import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import Select from 'react-select';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory, withRouter } from 'react-router-dom';
import { useSnackbar } from 'material-ui-snackbar-provider';

const useStyles = makeStyles((theme) => ({
  wapperContainer: {
    marginTop: 50,
    marginBottom: 50,
  },
  formSelectControl: {
    padding: theme.spacing(1) + 4,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  gridButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  formContainerMarginTop: {
    marginTop: 30,
    paddingTop: theme.spacing(3),
  },
  centerLabel: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  row: {
    marginTop: theme.spacing(2),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  textArea: {
    minHeight: 93,
  },
  tableMarginTop: {
    marginTop: theme.spacing(4),
  },
}));

const GET_PLATFORMSOURCE = gql`
  query PlatformSource {
    platforms {
      value
      label
    }
  }
`;

const FETCH_TENANTS_QUERY = gql`
  query {
    tenants {
      id
      domainName
      domainId
      channels
      concurrentUser
      concurrentSessions
      onHoldUsers
      isActive
      adminNotification
    }
  }
`;

function Form({ props }) {
  const snackbar = useSnackbar();
  const classes = useStyles();

  const [checkedNotify, setNotify] = useState(true);
  const [checked, setChecked] = useState(true);

  const [errors] = useState({});

  const handleNotify = (event) => {
    setNotify(!checkedNotify);
    console.log('SET NOTIFY >>>>> ', setNotify);
  };

  const handleChecked = (e) => {
    setChecked(!checked);
    console.log('SET CHECKED >>>> ', setChecked);
  };

  const { data = {} } = useQuery(GET_PLATFORMSOURCE, {
    fetchPolicy: 'cache-and-network',
  });
  const [tenantCreate] = useMutation(CREATE_TENANT_MUTATION);

  const history = useHistory();

  const routeChange = () => {
    let path = `/tenants`;
    history.push(path);
  };

  const [values, setValues] = useState({
    domainName: '',
    domainId: '',
    concurrentUser: '',
    concurrentSessions: '',
    onHoldUsers: '',
    channels: [],
    notificationText: '',
    roles: ['manager', 'agent', 'supervisor'],
    adminRol: false,
  });

  const handleAdminRol = (e) => {
    if (values.roles.includes('admin')) {
      var index = values.roles.indexOf('admin');
      var removeRol = values.roles.slice(removeRol, index);
      setValues({ ...values, roles: removeRol, adminRol: false });
    } else if (!values.roles.includes('admin')) {
      var oldRol = values.roles;
      oldRol.push('admin');
      setValues({ ...values, roles: oldRol, adminRol: true });
    }
  };

  const onChange = (e) => {
    const {
      target: { name, value },
    } = e;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const onChangeSelect = (e) => {
    setValues({ ...values, channels: e });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      tenantCreate({
        variables: {
          tenantInput: {
            domainName: values.domainName,
            domainId: values.domainId,
            username: values.domainId,
            password: values.domainId,
            roles: values.roles,
            firstName: values.domainName,
            lastName: values.domainId,
            channels: values.channels,
            concurrentUser: parseInt(values.concurrentUser),
            concurrentSessions: parseInt(values.concurrentSessions),
            onHoldUsers: parseInt(values.onHoldUsers),
            adminNotification: checkedNotify,
            adminRol: values.adminRol,
            isActive: checked,
            notificationText: values.notificationText,
          },
        },
        refetchQueries: [{ query: FETCH_TENANTS_QUERY }],
        awaitRefetchQueries: true,
      })
        .then((r) => {
          console.log('response', r.data);

          if (r.data.tenantCreate !== undefined) {
            snackbar.showMessage('Tenant Created');
            e.target.reset();
            routeChange();
          }
        })
        .catch(console.error);
    } catch (e) {
      console.log(e);
    }
    e.target.reset();
  };

  return (
    <Container className={classes.wapperContainer}>
      <CssBaseline />
      <Grid item xs={12}>
        <Grid>
          <Typography variant="h2">Create Tenants</Typography>
        </Grid>
      </Grid>
      <Paper>
        <Container>
          <Grid item xs={12} className={classes.formContainerMarginTop}>
            <ValidatorForm onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className={classes.centerLabel}>
                        <FormLabel htmlFor="domainName" component="label">
                          Domain Name
                        </FormLabel>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={8}>
                      <Box>
                        <TextField
                          fullWidth
                          id="domainName"
                          name="domainName"
                          placeholder="ICQ24"
                          value={values.domainName}
                          required
                          onChange={onChange}
                          error={errors.domainName ? true : false}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container className={classes.row}>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className={classes.centerLabel}>
                        <FormLabel htmlFor="Domain_Id" component="label">
                          Domain Id
                        </FormLabel>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                      <Box>
                        <TextField
                          name="domainId"
                          id="domain_Id"
                          fullWidth
                          required
                          value={values.domainId}
                          placeholder="ICQ24"
                          onChange={onChange}
                          error={errors.domainId ? true : false}
                        />
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid container className={classes.row}>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className={classes.centerLabel}>
                        <FormLabel htmlFor="channel" component="label">
                          Channel *
                        </FormLabel>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={8} lg={8}>
                      <Box>
                        <Select
                          isMulti
                          name="channels"
                          label="channels"
                          labelId="channels"
                          id="channels"
                          value={values.channels}
                          onChange={onChangeSelect}
                          options={data.platforms}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          isClearable
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.row}>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className={classes.centerLabel}>
                        <FormLabel htmlFor="concurrent_users" component="label">
                          Concurrent users
                        </FormLabel>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                      <Box>
                        <TextField
                          name="concurrentUser"
                          id="concurrent_users"
                          type="number"
                          inputProps={{
                            min: '0',
                          }}
                          required
                          placeholder="25"
                          fullWidth
                          onChange={onChange}
                          value={values.concurrentUser}
                          error={errors.concurrentUser ? true : false}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12} md={4}>
                      <Box className={classes.centerLabel}>
                        <FormLabel
                          htmlFor="concurrent_sessions"
                          component="label"
                        >
                          Concurrent sessions
                        </FormLabel>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Box>
                        <TextField
                          name="concurrentSessions"
                          id="concurrent_sessions"
                          type="number"
                          inputProps={{
                            min: '0',
                          }}
                          required
                          placeholder="25"
                          onChange={onChange}
                          value={values.concurrentSessions}
                          error={errors.concurrentSessions ? true : false}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container className={classes.row}>
                    <Grid item xs={12} md={4}>
                      <Box className={classes.centerLabel}>
                        <FormLabel htmlFor="on_hold_sessions" component="label">
                          On hold sessions
                        </FormLabel>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Box>
                        <TextField
                          name="onHoldUsers"
                          id="on_hold_sessions"
                          type="number"
                          inputProps={{
                            min: '0',
                          }}
                          required
                          onChange={onChange}
                          placeholder="25"
                          value={values.onHoldUsers}
                          error={errors.onHoldUsers ? true : false}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid className={classes.row}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          onChange={handleChecked}
                          checked={checked}
                          name="is Active"
                          value="active"
                        />
                      }
                      label="Is Active"
                    />
                  </Grid>
                  <Grid className={classes.row}>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleNotify}
                            checked={checkedNotify}
                            name="adminNotification"
                            color="primary"
                            value="active"
                          />
                        }
                        label="Admin Notification"
                      />
                    </Box>
                  </Grid>
                  <Grid>
                    <Box>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={handleAdminRol}
                            checked={values.adminRol}
                            name="adminRol"
                            color="primary"
                            value="active"
                          />
                        }
                        label="Admin Rol"
                      />
                    </Box>
                  </Grid>
                  <Grid className={classes.row} item xs={12}>
                    <Box xs={12}>
                      <TextField
                        id="notificationText"
                        multiline
                        fullWidth
                        name="notificationText"
                        placeholder="Welcome to ICQ24"
                        className={classes.textArea}
                        onChange={onChange}
                        error={errors.notificationText ? true : false}
                        value={values.notificationText}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={classes.row} item xs={12}>
                <Grid item xs={12} className={classes.gridButton}>
                  <Button color="primary" type="submit" onClick={routeChange}>
                    CANCEL
                  </Button>
                  <Button color="primary" type="submit">
                    Add
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Container>
      </Paper>
    </Container>
  );
}

// CREATE TENANT MUTATION
const CREATE_TENANT_MUTATION = gql`
  mutation tenantCreate($tenantInput: tenantInput) {
    tenantCreate(tenantInput: $tenantInput) {
      id
    }
  }
`;

export default withRouter(Form);

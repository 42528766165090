import { useMutation } from '@apollo/react-hooks';
import {
  Box,
  Button,
  Container,
  FormLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useHistory, withRouter } from 'react-router-dom';
import { useSnackbar } from 'material-ui-snackbar-provider';

const useStyles = makeStyles((theme) => ({
  wapperContainer: {
    marginTop: 50,
    marginBottom: 50,
  },
  formSelectControl: {
    padding: theme.spacing(1) + 4,
  },

  gridButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  formContainerMarginTop: {
    marginTop: 30,
    paddingTop: theme.spacing(3),
  },
  centerLabel: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  },
  row: {
    marginTop: theme.spacing(2),
  },
}));

function Form({ props }) {
  const snackbar = useSnackbar();
  const classes = useStyles();

  const [dialog360ApiKey] = useMutation(CREATE_DIALOG_360_API_KEY_MUTATION);

  const history = useHistory();

  const routeChange = () => {
    let path = `/tenants`;
    history.push(path);
  };

  const [values, setValues] = useState({
    channelId: '',
    apiKey: '',
  });

  const onChange = (e) => {
    const {
      target: { name, value },
    } = e;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      dialog360ApiKey({
        variables: {
          input: {
            channelId: values.channelId,
          },
        },
      })
        .then((r) => {
          console.log('response', r.data);

          if (r.data.dialog360ApiKey !== undefined) {
            snackbar.showMessage(
              'Api Key Created',
              r.data.dialog360ApiKey.api_key
            );
            setValues({ ...values, apiKey: r.data.dialog360ApiKey.api_key });
            e.target.reset();
          }
        })
        .catch(console.error);
    } catch (e) {
      console.log(e);
    }
    e.target.reset();
  };

  return (
    <Container className={classes.wapperContainer}>
      <CssBaseline />
      <Grid item xs={12}>
        <Grid>
          <Typography variant="h2">Create Dialog360 Api Key</Typography>
        </Grid>
      </Grid>
      <Paper>
        <Container>
          <Grid item xs={12} className={classes.formContainerMarginTop}>
            <ValidatorForm onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid item xs={12} md={4} lg={3}>
                      <Box className={classes.centerLabel}>
                        <FormLabel htmlFor="channelId" component="label">
                          Channel Id
                        </FormLabel>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={8}>
                      <Box>
                        <TextField
                          fullWidth
                          id="channelId"
                          name="channelId"
                          placeholder="channelId"
                          value={values.channelId}
                          required
                          onChange={onChange}
                        />
                      </Box>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4} lg={3}>
                        <Box className={classes.centerLabel}>
                          <FormLabel htmlFor="apiKey" component="label">
                            Api Key
                          </FormLabel>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4} lg={8} className={classes.row}>
                        <Box>
                          <TextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            id="apiKey"
                            placeholder="Api Key"
                            value={values.apiKey}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid item xs={8} className={classes.gridButton}>
                      <Button
                        color="primary"
                        type="submit"
                        onClick={routeChange}
                      >
                        CANCEL
                      </Button>
                      <Button color="primary" type="submit">
                        Create 
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ValidatorForm>
          </Grid>
        </Container>
      </Paper>
    </Container>
  );
}

// CREATE DIALOG 360 MUTATION
const CREATE_DIALOG_360_API_KEY_MUTATION = gql`
  mutation Dialog360ApiKey($input: D360ApiKeyInput) {
    dialog360ApiKey(input: $input) {
      api_key
    }
  }
`;

export default withRouter(Form);

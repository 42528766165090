import { useMutation, useQuery } from "@apollo/react-hooks";
import { Grid, Paper, TableContainer } from "@material-ui/core";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  List,
  NotInterested,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
  Visibility,
} from "@material-ui/icons";
import gql from "graphql-tag";
import MaterialTable from "material-table";
import { useSnackbar } from "material-ui-snackbar-provider";
import React, { forwardRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { SessionHelper } from '../../utils/index';
import AlertDialog from "./../../components/utils/alertDialog";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  List: forwardRef((props, ref) => <List {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Visibility: forwardRef((props, ref) => <Visibility {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  NotInterested: forwardRef((props, ref) => (
    <NotInterested {...props} ref={ref} />
  )),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const GET_PLATFORMS = gql`
  query PlatformSource {
    platforms {
      id
      value
      status
      label
    }
  }
`;

const DELETE_PLATFORMSOURCE = gql`
  mutation PlatformSourceDelete($id: ID!) {
    platformDelete(id: $id)
  }
`;

let idPlatformSourceDelete = false;

function PlatformSource(props) {
  const { data = {} } = useQuery(GET_PLATFORMS, {
    fetchPolicy: "network-only",
  });

  const [platformDelete] = useMutation(DELETE_PLATFORMSOURCE);
  const snackbar = useSnackbar();
  const [alertOpen, setAlertOpen] = useState(false);
  const alertOption = (option) => {
    setAlertOpen(false);
    if (option) {
      deletePlatform();
    }
  };

  const handleClickDeleteplatform = (id) => {
    setAlertOpen(true);
    idPlatformSourceDelete = id;
  };

  const deletePlatform = () => {
    platformDelete({
      variables: { id: idPlatformSourceDelete },
      refetchQueries: [{ query: GET_PLATFORMS }],
      awaitRefetchQueries: true,
    })
      .then((r) => {
        if (r.data.platformsourceDelete !== null) {
          snackbar.showMessage("Deleted");
        }
      })
      .catch(console.error());
  };

  const history = useHistory();
  const routeChange = (path) => {
    history.push(path);
  };
  const currentUsername = SessionHelper?.currentUser?.username;
  let actions = [
    {
      icon: tableIcons.Edit,
      tooltip: "Edit",
      onClick: (event, rowData) => {
        routeChange(`/SourceEdit/${rowData.id}`);
      },
    },

    {
      icon: tableIcons.Delete,
      tooltip: "Delete",
      onClick: (event, rowData) => handleClickDeleteplatform(rowData.id),
    },
    {
      icon: tableIcons.Add,
      tooltip: "Create",
      isFreeAction: true,
      onClick: () => routeChange(`sourceCreate`),
    },
  ];
  if (currentUsername === "ICQ24") actions = [];
  const columns = [
    {
      id: "source",
      title: "Source",
      label: "Source",
      minWidth: 120,
      field: "value",
    },

    {
      id: "label",
      title: "Label",
      label: "label",
      minWidth: 120,
      field: "label",
    },
    {
      id: "status",
      title: "Status",
      label: "status",
      minWidth: 120,
      field: "status",
      render: (rowData) =>
        rowData && (rowData.status ? <Check /> : <NotInterested />),
    },
  ];

  return (
    <Paper square>
      <Grid container>
        <Grid item xs={12} md={12}>
          <TableContainer component={Paper}>
            <MaterialTable
              columns={columns}
              data={data.platforms}
              icons={tableIcons}
              options={{
                search: true,
                sorting: true,
                actionsColumnIndex: -1,
              }}
              title="Source List"
              actions={actions}
            />
          </TableContainer>
        </Grid>
        <AlertDialog open={alertOpen} alertOption={alertOption} />
      </Grid>
    </Paper>
  );
}

export default PlatformSource;
